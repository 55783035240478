import React, { Fragment, useCallback, useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AsyncLoadable from "./utils/AsyncLoadable";
import { defaultThemeId, themes, useAntdTheme } from "./theming";
import { SocketProvider } from "./utils/SocketContext";
import Password from "antd/lib/input/Password";
// import InvitesVerification from "./pages/InvitesVerification";
const DefaultLayout = AsyncLoadable(() =>
  import(/* webpackChunkName: 'default' */ "./containers")
);
const View404 = AsyncLoadable(() =>
  import(/* webpackChunkName: '404' */ "./views/Others/404")
);
const View500 = AsyncLoadable(() =>
  import(/* webpackChunkName: '500' */ "./views/Others/500")
);
const Login = AsyncLoadable(() =>
  import(/* webpackChunkName: 'login' */ "./pages/Login")
);
const ResetPassword = AsyncLoadable(() =>
  import(/* webpackChunkName: 'Reset' */ "./pages/Login/resetPassword")
);

const options = themes.map((theme) => ({
  label: theme.displayName,
  value: theme.id,
}));



export default function App() {
 
  const [apiDomain, setApiDomain] = useState("");
  const [themeId, setThemeId] = useState(defaultThemeId);
  useAntdTheme(themeId);
  const selectThemeId = useCallback((option) => setThemeId(option), []);
  const themeProps = { selectThemeId, options };
  var condition = navigator.onLine ? "online" : "offline";
  if (condition === "online") {
    fetch("/", {
      // Check for internet connectivity
      mode: "no-cors",
    })
      .then(() => {
        console.log("CONNECTED TO INTERNET");
      })
      .catch(() => {
        console.log("INTERNET CONNECTIVITY ISSUE");
      });
  } else {
    console.log("OFFLINE");
  }
  useEffect(() => {
    const currentDomain =
      window.location.protocol + "//" + window.location.hostname;
    setApiDomain(currentDomain);
  }, []);

  localStorage.setItem("BaseURL", apiDomain);



  return (
    <>
      <Fragment>
        <Switch>
          <Route path={"/"} exact render={() => <Redirect to={"/login"} />} />
          {/* <Route path={"/verify_email/:token"} component={InvitesVerification} /> */}

          <Route path={"/500"} component={View500} />
          <Route path={"/login"} component={Login} />
          <Route path={"/404"} component={View404} />
          <Route
            path={"/reset-password/:token/:token/"}
            component={ResetPassword}
          />
            <SocketProvider>
              <Route component={DefaultLayout} {...themeProps} />
            </SocketProvider>
        </Switch>
      </Fragment>
    </>
  );
}
